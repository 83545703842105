import Animated, { Revealed } from 'components/animated'
import Box, { Content, Flex, Grid, Section } from 'components/box'
import Button, { SecondaryButton } from 'components/button'
import convertImageMap from 'helpers/convertImageMap'
import CallToAction from 'components/sections/cta'
import IO from 'components/io'
import Layout from 'components/layout'
import Products from 'components/sections/products'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Screenshot from 'components/screenshot'
import Text, { Caps, Heading, Lead } from 'components/text'
import { AppContext } from 'store/app'
import { graphql } from 'gatsby'
import { Triangle } from 'components/icon'

const Timer = ({ data }) => {
  const { focusSubscriptionForm } = useContext(AppContext).appState
  const scrollToLearnMore = () =>
    document.querySelector('#intro').scrollIntoView({ behavior: 'smooth' })
  const { page } = data
  const images = convertImageMap(page.images)
  const screens = convertImageMap(page.screens)

  return (
    <Layout pageTitle={page.title}>
      <Section hero>
        <Content my={5}>
          <Grid columns={[1, 1, 2]} gridGap={[4, 4, 0]}>
            <Box
              gridRow={[2, 2, 'auto']}
              textAlign={['center', 'center', 'left']}
            >
              <Caps
                as={Revealed}
                alignItems="center"
                color="red"
                display="inline-flex"
                fontSize={[5, 4]}
                ml={[-3, -3, 0]}
                my={3}
              >
                <Triangle mr={3} opacity={0.5} />
                {page.title}
              </Caps>
              <Heading
                as={Revealed}
                forwardedAs="h1"
                delay={1}
                level={0}
                measure="tight"
                mx={['auto', 'auto', 'initial']}
              >
                {page.tagline}
              </Heading>
              <Lead
                as={Revealed}
                forwardedAs="p"
                delay={2}
                fontWeight="medium"
                measure="narrow"
                mb={5}
                mt={3}
                mx={['auto', 'auto', 'initial']}
              >
                {page.description}
              </Lead>
              <Revealed
                containerProps={{ display: 'inline-flex', mb: 2, mr: 2 }}
                delay={4}
              >
                <Button
                  onClick={focusSubscriptionForm}
                  color="red"
                  size="large"
                >
                  Join Beta
                </Button>
              </Revealed>
              <Revealed containerProps={{ display: 'inline-flex' }} delay={5}>
                <SecondaryButton
                  onClick={scrollToLearnMore}
                  color="red"
                  size="large"
                >
                  Learn more
                </SecondaryButton>
              </Revealed>
            </Box>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              position="relative"
              px={3}
            >
              <Revealed
                as={Triangle}
                bottom={[0, '-7.5%']}
                color="red-050"
                duration={6}
                left={['-25%', '-20%', '-25%']}
                position="absolute"
                preserveAspectRatio="xMinYMax meet"
                size="250%"
                zIndex={-1}
              />
              <Box
                as={Revealed}
                bg="red-100"
                alignItems="center"
                borderRadius={2}
                delay={3}
                duration={7}
                display="flex"
                justifyContent="center"
                p={2}
                position="relative"
                my={3}
              >
                <Triangle color="white" fontSize={4} />
                <Triangle
                  bottom={2}
                  color="red"
                  style={{ transformOrigin: 'bottom' }}
                  transform="scale(0.4)"
                  position="absolute"
                  fontSize={4}
                />
                <Text
                  borderRadius={2}
                  top="65% "
                  left="65%"
                  bg="red-400"
                  border={2}
                  borderColor="red-050"
                  px={1}
                  color="white"
                  fontSize={8}
                  fontWeight="bold"
                  lineHeight={2}
                  position="absolute"
                  truncate
                >
                  10m
                </Text>
              </Box>
              <Screenshot
                as={Revealed}
                aspectRatio="5:4"
                containerProps={{
                  maxWidth: 'narrow',
                  width: '100%',
                }}
                delay={4}
                duration={8}
                screen={screens['Timer']}
              />
            </Flex>
          </Grid>
        </Content>
      </Section>

      <Section id="intro" display="flex" justifyContent="center">
        <Content p={0}>
          <Box
            as={Revealed}
            backgroundImage={`url(${images['Background'].fluid.src})`}
            backgroundSize="cover"
            bg="red-025"
            borderRadius={3}
            duration={6}
            style={{
              backgroundBlendMode: 'screen',
            }}
          >
            <Box
              backgroundImage={`url(${images['Background'].fluid.src})`}
              backgroundSize="cover"
              bg="red"
              color="white"
              p={[4, 5]}
              style={{
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              <Heading level={2} measure="normal">
                The Inspiration
              </Heading>
              <Lead fontWeight="medium" measure="normal" mt={2}>
                Time spent online should be time well spent.
              </Lead>
              <Text
                as="p"
                color="neutral-500"
                fontSize={4}
                measure="wide"
                mt={4}
              >
                We've been inspired by companies providing first-party solutions
                to usage tracking. Apple's Screen Time, Google's Digital
                Wellbeing controls, Instagram, and YouTube's time limit settings
                are just a few examples.
              </Text>
              <Text
                as="p"
                color="neutral-500"
                fontSize={4}
                measure="wide"
                mt={3}
              >
                Our goal is to provide a seamless browser time tracker that
                provides both high-level and deep insights along with
                user-friendly restriction controls.
              </Text>
            </Box>
          </Box>
        </Content>
      </Section>

      <Section>
        <Content my={[5, 5, 6]}>
          <Box>
            <Heading
              as={Revealed}
              forwardedAs="h2"
              level={2}
              mt={4}
              textAlign="center"
            >
              How it works
            </Heading>
            <Lead
              as={Revealed}
              forwardedAs="p"
              delay={1}
              mt={1}
              textAlign="center"
            >
              Start tracking in no time
            </Lead>
          </Box>
          <IO>
            {({ isVisible, hasBeenVisible }) => (
              <Grid columns={[1, 2, 3]} gridGap={[5, 5, 4]} mt={5}>
                <Animated
                  delay={1}
                  duration={5}
                  isVisible={isVisible || hasBeenVisible}
                  position="relative"
                >
                  <Box
                    width="100%"
                    maxWidth="narrow"
                    mx="auto"
                    textAlign="center"
                  >
                    <Screenshot
                      aspectRatio="5:4"
                      screen={screens['Set Timer']}
                    />
                  </Box>
                  <Box px={3} py={4}>
                    <Heading level={4} lineHeight={2} textAlign="center">
                      Know at a Glance
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      mt={1}
                      measure="normal"
                      mx="auto"
                      textAlign="center"
                    >
                      Be aware of how much time you spent on the current website
                      by simply glancing at the extension's icon and badge.
                    </Text>
                  </Box>
                </Animated>
                <Animated
                  delay={2}
                  duration={5}
                  isVisible={isVisible || hasBeenVisible}
                  position="relative"
                >
                  <Box
                    width="100%"
                    maxWidth="narrow"
                    mx="auto"
                    textAlign="center"
                  >
                    <Screenshot
                      aspectRatio="5:4"
                      screen={screens['Hover Session']}
                    />
                  </Box>
                  <Box px={3} py={4}>
                    <Heading level={4} lineHeight={2} textAlign="center">
                      Dive Deeper
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      mt={1}
                      measure="normal"
                      mx="auto"
                      textAlign="center"
                    >
                      Click the extension to see the number of visits you made
                      today including an overview of your session timeline.
                    </Text>
                  </Box>
                </Animated>
                <Animated
                  delay={3}
                  duration={5}
                  isVisible={isVisible || hasBeenVisible}
                  position="relative"
                >
                  <Box
                    width="100%"
                    maxWidth="narrow"
                    mx="auto"
                    textAlign="center"
                  >
                    <Screenshot aspectRatio="5:4" screen={screens['Timer']} />
                  </Box>
                  <Box px={3} py={4}>
                    <Heading level={4} lineHeight={2} textAlign="center">
                      Set the Timer
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      mt={1}
                      measure="normal"
                      mx="auto"
                      textAlign="center"
                    >
                      If you'd like to limit your time spending on the current
                      website, just set the timer and reset schedule.
                    </Text>
                  </Box>
                </Animated>
                <Animated
                  as={Box}
                  delay={4}
                  duration={5}
                  gridColumn={['span 1', 'span 1', 'span 3']}
                  isVisible={isVisible || hasBeenVisible}
                  position="relative"
                >
                  <Box
                    width="100%"
                    maxWidth={['narrow', 'narrow', 1]}
                    mx="auto"
                    textAlign="center"
                  >
                    <Screenshot
                      aspectRatio={['5:4', '5:4', '9:21']}
                      border={[1, 1, 3]}
                      borderColor={[
                        'neutral-100',
                        'neutral-100',
                        'neutral-050',
                      ]}
                      borderRadius={[2, 2, 3]}
                      bg="neutral-025"
                      elevation={0}
                      screen={{
                        imgStyle: { objectFit: 'contain' },
                        ...screens['Times Up'],
                      }}
                    />
                  </Box>
                  <Box px={3} py={4}>
                    <Heading level={4} lineHeight={2} textAlign="center">
                      Get a Nudge
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      mt={1}
                      measure="normal"
                      mx="auto"
                      textAlign="center"
                    >
                      When the timer is up, the website is blocked and you get a
                      gentle reminder.
                    </Text>
                  </Box>
                </Animated>
              </Grid>
            )}
          </IO>
        </Content>
      </Section>
      <CallToAction />
      <Products />
    </Layout>
  )
}

Timer.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Timer

export const query = graphql`
  query TimerPage {
    page: timerJson {
      title
      tagline
      description
      images {
        title
        image {
          childImageSharp {
            fluid(toFormat: JPG, jpegProgressive: true) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      screens {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
